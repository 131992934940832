<template>
  <div>
      <v-main class="pa-0" style="background-color:#fff; min-height:100vh">
        <v-container fluid :style="mobilePhone?{width:'100%'}:{width:'80%'}" class="justify-content-center">
        <v-row class="justify-content-center">
            <v-col cols="12" align="left" :class=" mobilePhone ? 'mt-10':''">
                <h1 class="headline font-weight-bold" :style="mobilePhone?{textAlign:'center'}:''">
                Pertanyaan Umum
                </h1>
                <a href="https://drive.google.com/drive/folders/1Yx_di4LCmMNCRt5DPYTAwiUFNkLWlN_e?usp=sharing" target="_blank" :style="mobilePhone?{textAlign:'center'}:''"><p style="cursor:pointer">Cari tahu lebih lanjut tentang K-BUFU</p></a>
                <v-divider />
                <v-list>
                    <!-- <v-list-item-title :style="{color:'rgb(136 136 136)'}">Test</v-list-item-title> -->

                    <v-list-group
                        :value="false"
                    >
                        <template v-slot:activator>
                        <v-list-item-content>Bagaimana cara Top-up simpanan?</v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-list-item-content class="content">
                                <p>
                                    <ol>
                                        <li>Silahkan untuk membuka terlebih dahulu menu Top-up. <a href="https://k-bufu.id/topup">https://k-bufu.id/topup</a></li>
                                        <li>Kemudian anggota, memilih jenis simpanan yang akan di topup.
                                            <ul>
                                                <b>Khusus untuk simpanan wajib</b>
                                                <li>Pilih periode simpanan dahulu, bisa memilih lebih dari 1 periode bulan.</li>
                                            </ul>
                                        </li>
                                        <li>Lalu akan muncul form untuk memasukan nominal dari besaran simpanan yang akan dibayar.</li>
                                        <li>Terakhir memilih metode pembayaran, lalu klik buat tagihan.</li>
                                    </ol>
                                </p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        :value="false"
                    >
                        <template v-slot:activator>
                        <v-list-item-content>Bagaimana bila sudah membayar simpanan, tetapi tidak membuat tagihan terlebih dahulu di aplikasi?</v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-list-item-content class="content">
                                <p>Bila anggota sudah membayar simpanan, tetapi tidak membuat tagihan terlebih dahulu. Mohon segera untuk menghubungi customer service kami melalui <a :href="contactWA()">whatsapp</a> atau <a :href="contactEmail()">email</a>, dengan melampirkan bukti pembayaran.</p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        :value="false"
                    >
                        <template v-slot:activator>
                        <v-list-item-content>Mengapa tanggal pada mutasi tidak sesuai dengan tanggal ketika saya bayar?</v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-list-item-content class="content">
                                <p>Tanggal pada mutasi ter-catat pada saat anggota membuat tagihan simpanan pada menu Top-up, bukan ketika membayar simpanan tersebut.
                                    Atau bila anggota membayar tidak membuat tagihan dahulu di aplikasi, tanggal ter-catat ketika admin/developer menambahkan data tagihan anda.
                                </p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        :value="false"
                    >
                        <template v-slot:activator>
                        <v-list-item-content>Mengapa periode pada topup simpanan wajib ada bulan yang bisa dipilih dan tidak?</v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-list-item-content class="content">
                                <p>Periode pada topup simpanan wajib di batasi dengan rentang waktu 2 tahun kedepan, dimulai dari per bulan saat ini.
                                   Periode tersebut juga bisa mengidentifikasi-kan bahwa yang bersangkutan belum membayar simpanan wajib pada bulan tersebut, 
                                   bila anggota sudah membayar simpanan wajib pada bulan-bulan yang dipilih maka bulan ter-sebut akan otomatis tidak bisa di pilih / tidak aktif (dengan catatan data pembayar sudah di rekonsiliasi oleh admin/saldo sudah berubah).
                                   Bila ternyata bulan masih aktif, mohon segera untuk menghubungi customer service kami melalui <a :href="contactWA()">whatsapp</a> atau <a :href="contactEmail()">email</a>
                                </p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        :value="false"
                    >
                        <template v-slot:activator>
                        <v-list-item-content>Mengapa ketika sudah topup dan transafer uang, saldo pada aplikasi belum ter-update?</v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-list-item-content class="content">
                                <p>Saldo pada aplikasi akan ter-update apabila data pembayaran yang bapak/ibu lakukan sudah di rekonsiliasi oleh admin. 
                                Mohon untuk menunggu 1x24 jam, bila saldo pada aplikasi masih belum ter-update segera untuk menghubungi customer service kami melalui <a :href="contactWA()">whatsapp</a> atau <a :href="contactEmail()">email</a></p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        :value="verification"
                    >
                        <template v-slot:activator>
                            <v-list-item-content>Untuk apa dan bagaimana cara verifikasi KTP / PAS FOTO?</v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-list-item-content class="content">
                                <ul style="line-height: 18px;">
                                    <li>Verifikasi KTP diperlukan untuk pencocokan/pendataan anggota dengan database koperasi. </li>
                                    <li>Verifikasi PAS FOTO diperlukan untuk foto pada kartu tanda anggota <i>(KTA)</i> koperasi. </li>
                                </ul>
                                <p>
                                    Cara melakukan verifikasi:
                                    <ol>
                                        <li>Buka profile anggota. <a href="https://k-bufu.id/profile">https://k-bufu.id/profile</a></li>
                                        <li>Buka menu Akun -> Verifikasi</li>
                                        <li>Upload foto KTP / PAS FOTO</li>
                                        <li>FYI: bila tombol verifikasi tidak bisa di klik artinya anggota sudah melakukan verifikasi</li>
                                    </ol>
                                </p>
                                <p>Informasi lebih lanjut silahkan menghubungi customer service kami melalui <a :href="contactWA()">whatsapp</a> atau <a :href="contactEmail()">email</a></p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group
                        :value="withdrawal">
                            <template v-slot:activator>
                                <v-list-item-content>Mengapa tidak ada rekening yang tertampil ketika akan melakukan penarikan?</v-list-item-content>
                            </template>
                            <v-list-item>
                                <v-list-item-content class="content">
                                    <p>
                                        Pastikan anda sudah menambahkan rekening di halaman profil anggota <br />
                                        <a href="https://k-bufu.id/profile">https://k-bufu.id/profile</a>
                                    </p>
                                    <p>Rekening penarikan dapat tertampil apabila rekening yang anda tambahkan sudah dilakukan pengecekan oleh Admin K-BUFU (Approved) atau lihat status rekening dibawah ini untuk lebih lanjut</p>
                                        <li><b>Approved</b></li>
                                         Rekening yang diajukan user sudah di approved oleh Admin.
                                        <li><b>On Review</b></li>
                                        Rekening yang sudah diajukan oleh user, tapi belum di approve oleh Admin.
                                        <li><b>Revised</b></li>
                                        Rekening yang pengajuannya perlu di revisi oleh user, sebelumnya admin tidak approve karena no rekening dan nama rekening tidak valid.
                                </v-list-item-content>
                            </v-list-item>
                    </v-list-group>
                </v-list>
            </v-col>
        </v-row>
        </v-container>
      </v-main>
  </div>
  
</template>

<script>
import {contactWA, contactEmail} from '@/lib/util/utilities.js'

export default {
  name: 'GeneralQuestions',
  components: {
  },
  data() {
    return {
      verification: false,
      withdrawal: false,
      mobilePhone: this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm',
      showBottomNav: false
    }
  },
  methods:{
    contactWA, contactEmail,
    routerParams() {
        if(this.$router.currentRoute.hash === '#verification'){
            this.verification = true
            if (this.showBottomNav) {
                window.scrollTo({
                top:600,
                left: 0,
                behavior: 'smooth'
                });   
            }
        }
        if(this.$router.currentRoute.hash === '#withdrawal'){
            this.withdrawal = true
            if (this.showBottomNav) {
                window.scrollTo({
                top:600,
                left: 0,
                behavior: 'smooth'
                });   
            }
        }
    }
  },
  mounted() {
    switch (this.$vuetify.breakpoint.name) {
        case 'xs': 
        this.showBottomNav = true
        break
        case 'sm': 
        this.showBottomNav = true
        break
        case 'md': 
        this.showBottomNav = false
        break
        case 'lg': 
        this.showBottomNav = false
        break
        case 'xl': 
        this.showBottomNav = false
        break
    }
    this.routerParams()
  },
}
</script>
<style scoped>
.content{
    font-size:13px;
    text-align:justify;
    letter-spacing: 1px;
    color: #5f636f;
}

p{
    line-height: 18px;
}

a{
    text-decoration: none;
    font-weight: bold;
}

</style>
